




















































































































































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Watch, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { IdoPoolDetailViewModel } from '../viewmodels/ido-pool-detail-viewmodel'

@Observer
@Component({
  components: {
    'pool-state': () => import('../components/pool-state.vue'),
    'ido-pool-swap': () => import('./ido-pool-swap.vue')
  }
})
export default class IdoPoolDetail extends Vue {
  @Provide() vm = new IdoPoolDetailViewModel()

  tab = null
  headers = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Token Allocation', value: 'purchase.amount', sortable: false },
    { text: 'Trade Amount', value: 'purchase.ethAmount', sortable: false },
    { text: 'Date', value: 'purchase.timestamp', sortable: false },
    { text: 'Action', value: 'action', sortable: false }
  ]
  bslheaders = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Token Allocation', value: 'purchase.amount', sortable: false },
    { text: 'Trade Amount', value: 'purchase.ethAmount', sortable: false },
    { text: 'Valid From', value: 'purchase.validAfterDate', sortable: false },
    { text: 'Action', value: 'action', sortable: false }
  ]
  walletStore = walletStore

  @Watch('$route.params.poolid', { immediate: true }) onPoolIdChanged(val: string) {
    if (val) {
      this.vm.loadPool(val)
    }
  }

  async joinPool() {
    if (this.vm.allowSwap) {
      const dialog = this.$refs.swapDialog as any
      await dialog.open()
      this.vm.loadPurchaseds()
    }
  }

  viewOnBsc() {
    window.open(`https://bscscan.com/address/${this.vm.pool?.address}`, '_blank')
  }

  destroyed() {
    this.vm.destroy()
  }
}
